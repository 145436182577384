import "./styles/forgetPassword.scss";

import React, {useState, useContext} from 'react';
import TextField from "../components/TextField";
import Button from "../components/Button";

import { UserContext, UserContextInterface  } from "../providers/userprovider";

const ForgetPassword = (props : any) => {

    const context : UserContextInterface = useContext(UserContext);

    const [email, setEmail] = useState<string>("");
    const [submit, setSubmit] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const forgetPassword = () => {
        if (email.length === 0) {
            return setError("Email field empty")
        }

        context.api.submitPasswordReset(email)
            .then((res) => {
                setSubmit(true);
            })
            .catch((error) => {
                setError(JSON.stringify(error));
            })
    }

    if(submit)
    {
        return <div className="forget-password-root">
            <header>
                <h2>Email reset sent to {email}</h2>
            </header>
        </div>
    }

    return <div className="forget-password-root">

        <header>
            <h2>Forget Password</h2>
        </header>

        <div className="form-options">
            <TextField
                value={email}
                width="100%"
                onChange={(event : any) => {setEmail(event.target.value)}}
                placeholder="Email"
            >

            </TextField>

            <div className="error-box">
                {error}
            </div>

            <Button
                color="white"
                width="100%"
                style={{
                    color: "black"
                }}
                onClick={(event : any) => {forgetPassword()}}
            >Send Password Reset</Button>
        </div>
    </div>
}

export default ForgetPassword;