export const TOKEN_KEY : string = "gym-account-token"
export const EMAIL_KEY : string = "email";

const DEV_IP : string = "http://192.168.1.11:8000";
//"http://192.168.1.11:8000"
//"http://localhost:8000"
const PROD_IP : string = ""
export const BASE_URI : string = process.env.NODE_ENV === "development" ? DEV_IP : PROD_IP;

const constants = {
    TOKEN_KEY,
    EMAIL_KEY,
    BASE_URI
}

export default constants;