import React, {useState} from 'react';

import "./styles/workoutContainer.scss";
import {WorkoutSet} from "../utils/types";

import editIcon from "../assets/icons/edit.svg";

type WorkoutContainerProps = {
    name: string,
    workoutNote: string,
    workoutId: number,
    sets: Array<WorkoutSet>,
    children: any
    onDeleteWorkout : Function,
    onEdit?: Function,
    extendDefault?: boolean,
}

const CONTAINER_EXTEND_HEIGHT = '150px'
const CONTAINER_NON_EXTEND_HEIGHT = '20px'

const WorkoutContainer = (props : WorkoutContainerProps) => {

    const [isExtended, setIsExtended] = useState<boolean>(props.extendDefault ? props.extendDefault : false);

    return (
        <div 
            className="workout-container-root"
            style={{
                height: isExtended ? CONTAINER_EXTEND_HEIGHT : CONTAINER_NON_EXTEND_HEIGHT,
            }}
            onClick={() => setIsExtended(!isExtended)}
        >
            <div className="workout-container-header">
                <div
                    className="workout-name"
                >{props.name}</div>

                {/* ================================================================== */}
                <div
                    className="extend-icon"
                    style={{
                        display: isExtended ? "none" : "block"
                    }}
                >
                <b>↓</b>
                </div>

                {/* ================================================================== */}
                <div
                    className="edit-button"
                    onClick={(event : any) => {return props.onEdit ? props.onEdit() : ""}}
                    style={{
                        display: isExtended ? "block" : "none"
                    }}
                >
                    <img 
                        src={editIcon} 
                        alt={"edit"}
                    ></img>
                </div>
                <div 
                    className="delete-button"
                    onClick={() => {
                        props.onDeleteWorkout(props.workoutId)
                    }}    
                    style={{
                        display: isExtended ? "block" : "none"
                    }}
                >
                    X
                </div>
            </div>
            <div 
                className="extended-container"
                style={{
                    display: isExtended ? "block" : "none"
                }}
            >
                <div 
                    className="workout-container-note"
                    style={{
                        display: props.workoutNote ? "flex" : "none"
                    }}    
                >
                    <div className="note-label">Note: </div>
                    <div className="workout-note">{props.workoutNote}</div>
                </div>

                {props.sets.map((set, index) => {
                    return (
                    <div 
                        key={index}
                        className="display-set-container">
                            <div
                                className="sets"
                            >Set {index+1}:</div>
                            <div
                                className="reps"
                            >{set.reps} reps</div>
                            
                            <div
                                className="weight-or-bodyweight"
                            >{set.weight === -1 ? "BW" : set.weight + " lbs"}</div>
                            
                            
                            <div
                                className="notes"
                            >
                                {set.note}
                                {/* <div className="note-container">
                                    {set.note}
                            
                                </div> */}
                            </div>
                    </div>)
                })}
            </div>
            
        </div>
    );
}

export default WorkoutContainer;