import { BASE_URI } from "../utils/constants";
import {
    createWorkoutRoute, 
    deleteWorkoutRoute, 
    getAccountRoute, 
    editWorkoutRoute, 
    downloadExcelRoute
} from "./routes";

export const getAccountInfo = (email:string, token : string) : Promise<any> => {

    return new Promise((resolve, reject) => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Token " + token);
    
        const payload = { "email": email }
    
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(payload)
        }
    
        fetch(`${BASE_URI}/api/${getAccountRoute}`, requestOptions)
            .then((res) => { return res.json()})
            .then((data) => {
                if(data.error) {
                    reject(data.error);
                }
                
                if(data.success) {
                    resolve(data.success);
                }
                
                reject("Something went wrong with your request");
            })
            .catch((err) => reject(err))
    })

}

export const createWorkout = (email: string, data : any, token: string) : Promise<any> => {
    return new Promise((resolve, reject) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Token " + token);

        const raw = JSON.stringify(Object.assign(
            data, 
            {
                "email": email
            }
        ));
        
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        fetch(`${BASE_URI}/api/${createWorkoutRoute}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.error) {
                    reject(data.error);
                }
                resolve(data.success);

            })
            .catch(error => console.log('error', error));
    })
}

export const updateWorkout = (email: string, workoutId : number, data : any, token: string) : Promise<any> => {
    return new Promise((resolve, reject) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Token " + token)

        const raw = JSON.stringify(Object.assign(
            data, 
            {
                "workout_id": workoutId,
                "email": email
            }
        ));
        
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
          };
          
        fetch(`${BASE_URI}/api/${editWorkoutRoute}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.error) {
                    reject(data.error);
                }
                resolve(data.success);
            
            })
            .catch(error => console.log('error', error));
    })
}

export const deleteWorkout = (email: string, workoutId: number, token: string) : Promise<any> => {
    return new Promise((resolve, reject) => {

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Token " + token);

        const payload = {
            "email": email,
            "workout_id": workoutId
        }

        const requestOptions = {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(payload)
        }

        fetch(`${BASE_URI}/api/${deleteWorkoutRoute}`, requestOptions)
            .then((res) => res.json())
            .then((data) => {
                if(data.error) {
                    reject(data.error);
                }
                resolve(data.success);
            })
            .catch((err) => {
                reject(err);
            })
    })
}

export const excelDownload = (email: string, token: string) : Promise<any> => {
    return new Promise((resolve, reject) => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Token " + token);
        const payload = { 
            email: email
         }

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(payload)
        }

        fetch(`${BASE_URI}/api/${downloadExcelRoute}`, requestOptions)
            .then((res) => res.blob())
            .then((blob) => {
                resolve(blob);
            })
            .catch((err) => reject(err))
    })
}
