import React, {useState, useContext} from "react";

import Button from "../../components/Button";

import "./styles/taskbarMobile.scss"
import MenuIcon from "../../assets/icons/menu.svg";
import UserProfileIcon from "../../assets/icons/user-profile.svg";

import { useHistory } from 'react-router-dom'
import { UserContextInterface } from "../../providers/userprovider";
import { UserContext } from "../../providers/userprovider";

const MENU_WIDTH = "70vw";

const menuOptions = ["Add Workout","Account", "Logout"]

type TaskbarMobileProps = {
    shouldRender: boolean,
    name: string
}

const TaskbarMobile = (props : TaskbarMobileProps) => {

    const userContext : UserContextInterface = useContext(UserContext);
    const history = useHistory();
    const [isTrigger, setIsTrigger] = useState<boolean>(false); 

    const clickMenuOption = (value : string) => {
        switch(value)
        {
            case "Add Workout":
                history.push("/add-workout")
                break;
            case "Account":
                alert("Email: " + userContext.email)
                break;
            case "Logout":
                userContext.logout()
                break;
            default:
                
        }
    }

    return(<div 
        className="taskbar-mobile-root"
        style={{
            "display": props.shouldRender ? "block" : "none"
        }}
        >
        <Button
            width="70px"
            height="40px"
            style={{
                "margin": "0px 0px 0px 0px",
                "background": "transparent",
                "padding": "0px 0px 0px 0px"
            }}
            onClick={() => {
                setIsTrigger(!isTrigger);
            }}
        >
            <img src={MenuIcon} alt="Menu"></img>
        </Button>
        <div 
            className="non-menu"
            style={{
                "display" : isTrigger ? "block" : "none"
            }}
            onClick={() => {
                setIsTrigger(!isTrigger)
            }}
        >

        </div>
        <div 
            style={{
                left: -MENU_WIDTH ? -MENU_WIDTH : "0px",
                width: MENU_WIDTH,
                transform: isTrigger ? "translate(0px)" : `translate(-${MENU_WIDTH})`
            }}
            className="menu-root">
                <div className="menu-header">
                    <img 
                        src={UserProfileIcon} alt="Profile"></img>
                    <div className="accountInfo">
                        {props.name}
                    </div>
                    <Button
                        style={{
                            width: "20%",
                            margin: "0px 0px 0px 0px",
                            background: "transparent",
                            padding: "0px 0px 0px 0px",
                            fontSize: "20px"
                        }}
                        onClick={() => {
                            setIsTrigger(!isTrigger)
                        }}
                    >
                        {"←"}
                    </Button>
                </div>

                <hr></hr>

                <div className="menu-options-containers">
                    {menuOptions.map((value, index) => {
                        return(<div 
                            className="option"
                            key={index}
                            onClick={() => clickMenuOption(value)}    
                        >
                            {value}
                        </div>)
                    })}
                </div>
        </div>
    </div>)
}

export default TaskbarMobile;