import * as React from 'react';

import './styles/landing.scss'

import Button from "../components/Button";

import ApolloLogo from '../assets/APOLLO.png';

type LandingProps = {
    
};

const Landing = (props: LandingProps) => {
    return (
        <div className="landing-root">
            <div className="header">

                <img
                    src={ApolloLogo}
                    alt="Apollo"
                ></img>


                <div className="border">
                    <h4>What gets measured gets done.</h4>
                </div>
            </div>

            <div className="body">
                <div>
                    Improve your gains by tracking your progress<br></br><br></br>
                    
                    Register or login to get started
                </div>

                <Button 
                    onClick={() => {
                        window.location.href = "/register"
                    }}
                    width="100%"
                    color="white"
                    style={{
                        color: "black"
                    }}
                >Register</Button>
                
                <br></br>

                <Button 
                    onClick={() => {
                        window.location.href = "/login"
                    }}
                    width="100%"
                    color="black"
                    style={{
                        border: "solid",
                        borderWidth: "1px"
                    }}
                >Login</Button>

            </div>
        </div>
    );
};

export default Landing;