import React, {useState, useRef, useEffect, useContext} from 'react';

import Button from "../../components/Button";
import { UserContextInterface, UserContext} from "../../providers/userprovider";

import "./styles/excelDownload.scss"

type ExcelDownloadProps = {
    name: string
};

const ExcelDownload = (props: ExcelDownloadProps) => {

    const context : UserContextInterface = useContext(UserContext);

    const [downloadURL, setDownloadURL] = useState<any>(null);
    const download : any = useRef<any>(null);

    const onFetchExcel = () => {

        context.api.excelDownload()
            .then((blob) => {
                const objectURL = URL.createObjectURL(blob);
                setDownloadURL(objectURL);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        if(downloadURL && download)
        {
            download.current.click();
            URL.revokeObjectURL(downloadURL);
            setDownloadURL(null);
        }
    }, [setDownloadURL, downloadURL])

    return (
        <div className="download-data-root">
            <Button
                width={"30%"}
                onClick={() => {
                    onFetchExcel()
                }}
                style={{
                    "cursor": "pointer",
                    "background": "transparent",
                    "padding": "0px 0px 0px 10px",
                    "text-align": "left"
                }}
                >
                Download Data ↓
        
            </Button>
            <a
                download={`${props.name}-data.csv`}
                href={downloadURL}
                ref={download}
                style={{
                    display: "none"
                }}
            >Test</a>
        </div>
        
    );
};

export default ExcelDownload;