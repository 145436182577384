export const createWorkoutRoute : string = "create-workout";
export const deleteWorkoutRoute : string = "delete-workout";
export const getAccountRoute : string = "get-account";
export const editWorkoutRoute : string = "edit-workout";
export const downloadExcelRoute : string = "download-excel";

export const loginRoute : string = "login";
export const registerRoute : string = "register";
export const verifyRoute : string = "verify";
export const submitPasswordResetRoute : string = "submit-password-reset";
export const changePasswordRoute : string = "change-password";
