import React, {useState} from "react";

import TextField from "./TextField";

import "./styles/selectExercise.scss"
import exercises from '../assets/exercises';
import infoIcon from '../assets/icons/info.svg';

type SelectExerciseProps = {
    selectExercise: Function
}

//https://www.liftlearngrow.com/blog-page/100-best-exercises-list/

const SelectExercise = (props : SelectExerciseProps) => {

    const [search, setSearch] = useState<string>("");

    const selectExercise = (exercise : string) => {
        props.selectExercise(exercise)
    }

    return(<div className="select-exercise-root">
        <label>Search</label>
        <TextField
            onChange={(event : any) => {setSearch(event.target.value)}}
            value={search}
            width={"100%"}
            placeholder={"Exercise Name"}
            color={"white"}
        ></TextField>
        <hr></hr>
        {/* {exercises.map((value, index) => {
            return (<div className="exerciseCard"
                onClick={() => {selectExercise(value)}}
            >
                {value}
            </div>)
        })} */}

        <div className="exerciseCard"
                onClick={() => {selectExercise(search)}}
                style={search.length === 0 ? {"display": "none"} : {}}
            >
                <div
                    className="exerciseName"
                    onClick={() => {selectExercise(search)}}
                >
                    Add "{search}"
                </div>

        </div>

        {exercises.exercises
        .filter((value, index) => {

            if(search.length === 0)
            {
                return true;
            }

            if(value["name"].toLowerCase().includes(search.toLowerCase()))
            {
                return true
            }

            return false
        }).map((value, index) => {

            return (<div className="exerciseCard"
                key={index}
            >
                <div 
                    className="exerciseName"
                    onClick={() => {selectExercise(value["name"])}}
                >
                    {value["name"]}
                </div>

                <div 
                    style={value["link"].length === 0 ? {"display": "none"} : {}}
                    className="exerciseInfoLink"
                    onClick={(event: any) => {
                        event.preventDefault()
                        window.open(value["link"], "_blank")
                    }}
                    >
                
                    <img 
                        src={infoIcon} 
                        alt="info"
                        style={{color: "white"}}
                        />
                </div>

            </div>)
        })}
    </div>)
}

export default SelectExercise;