const exercises = {
    "exercises": [
        {"name": "Incline barbell bench press", "link": "https://youtu.be/DbFgADa2PL8"} ,
        {"name": "Flat barbell bench press", "link": "https://youtu.be/rT7DgCr-3pg"} ,
        {"name": "Decline barbell bench press", "link": "https://www.youtube.com/watch?v=LfyQBUKR8SE"} ,
        {"name": "Guillotine (neck) press", "link": "https://youtu.be/XNilQyLwIec"} ,
        {"name": "Close grip bench press", "link": "https://www.youtube.com/watch?feature=youtu.be&v=cXbSJHtjrQQ"} ,
        {"name": "Incline dumbbell bench press", "link": "https://www.youtube.com/watch?t=9s&v=hChjZQhX1Ls"} ,
        {"name": "Flat dumbbell bench press", "link": "https://www.youtube.com/watch?t=5s&v=VmB1G1K7v94"} ,
        {"name": "Decline dumbbell bench press", "link": "https://www.youtube.com/watch?v=OQkxOqFCFWo"} ,
        {"name": "Dumbbell pullover", "link": "https://youtu.be/tpLnfSQJ0gg"} ,
        {"name": "Dumbbell chest fly", "link": "https://youtu.be/eozdVDA78K0"} ,
        {"name": "Dumbbell floor press", "link": "https://www.youtube.com/watch?v=uUGDRwge4F8"} ,
        {"name": "Cable chest fly", "link": "https://www.youtube.com/watch?v=Iwe6AmxVf7o"} ,
        {"name": "Cable chest press", "link": "https://www.youtube.com/watch?v=uBVsI54gJmU"} ,
        {"name": "Cable crossover", "link": "https://www.youtube.com/watch?v=taI4XduLpTk"} ,
        {"name": "Incline machine bench press", "link": "https://www.youtube.com/watch?v=ig0NyNlSce4"} ,
        {"name": "Flat machine bench press", "link": "https://youtu.be/xUm0BiZCWlQ"} ,
        {"name": "Pec dec", "link": ""} ,
        {"name": "Dip machine", "link": ""} ,
        {"name": "Weighted dips", "link": "https://youtu.be/sM6XUdt1rm4"} ,
        {"name": "Standard deadlift", "link": "https://youtu.be/4AObAU-EcYE"} ,
        {"name": "Sumo deadlift", "link": "https://youtu.be/1v4r9hht_K4"} ,
        {"name": "Romanian deadlift", "link": "https://youtu.be/JCXUYuzwNrM"} ,
        {"name": "Trap bar deadlift", "link": "https://youtu.be/PWJU5grrh4Y"} ,
        {"name": "Bent over rows", "link": "https://youtu.be/9efgcAjQe7E"} ,
        {"name": "Pendley rows", "link": "https://youtu.be/Weu9HMHdiDA"} ,
        {"name": "T-bar rows", "link": "https://youtu.be/j3Igk5nyZE4"} ,
        {"name": "Barbell shrugs", "link": "https://youtu.be/-0t_hCzUgvM"} ,
        {"name": "Barbell chest supported rows", "link": "https://www.youtube.com/watch?v=7vaiKROWCLc"} ,
        {"name": "Dumbbell Romanian deadlifts", "link": "https://youtu.be/FQKfr1YDhEk"} ,
        {"name": "Single-arm rows", "link": "https://youtu.be/pYcpY20QaE8"} ,
        {"name": "Dumbbell shrugs", "link": "https://youtu.be/xDt6qbKgLkY"} ,
        {"name": "Dumbbell chest supported rows", "link": "https://www.youtube.com/watch?v=H75im9fAUMc"} ,
        {"name": "Lat pulldowns – wide & narrow grip", "link": "https://youtu.be/CAwf7n6Luuc"} ,
        {"name": "Seated rows – wide & narrow grip", "link": "https://youtu.be/GZbfZ033f74"} ,
        {"name": "Standing push down", "link": "https://www.youtube.com/watch?v=AjCCGN2tU3Q"} ,
        {"name": "Hyperextensions", "link": "https://youtu.be/goynWxHQCWw"} ,
        {"name": "Chin-ups", "link": "https://youtu.be/brhRXlOhsAM"} ,
        {"name": "Pull-ups", "link": "https://youtu.be/ylVmNQlKdAI"} ,
        {"name": "Barbell back squats", "link": "https://youtu.be/1oed-UmAxFs"} ,
        {"name": "Barbell front squats", "link": "https://youtu.be/tlfahNdNPPI"} ,
        {"name": "Barbell lunges", "link": "https://youtu.be/0_9sJd9P8M0"} ,
        {"name": "Barbell Bulgarian split squat", "link": "https://www.youtube.com/watch?v=kkdmHTASZg8"} ,
        {"name": "Barbell step-ups", "link": "https://www.youtube.com/watch?v=bH8c6ladt1M"} ,
        {"name": "Good mornings", "link": "https://www.youtube.com/watch?v=vKPGe8zb2S4"} ,
        {"name": "Romanian deadlifts", "link": "https://youtu.be/JCXUYuzwNrM"} ,
        {"name": "Barbell hip thrusts", "link": "https://youtu.be/LM8XHLYJoYs"} ,
        {"name": "Standing calf raises", "link": "https://youtu.be/3UWi44yN-wM"} ,
        {"name": "Hack squat", "link": "https://www.youtube.com/watch?v=EdtaJRBqwes"} ,
        {"name": "Dumbbell front squat", "link": "https://www.youtube.com/watch?v=MJao9o7ROs0"} ,
        {"name": "Goblet squat", "link": "https://www.youtube.com/watch?t=2s&v=MeIiIdhvXT4"} ,
        {"name": "Dumbbell lunges", "link": "https://youtu.be/D7KaRcUTQeE"} ,
        {"name": "Dumbbell Bulgarian split squat", "link": "https://youtu.be/2C-uNgKwPLE"} ,
        {"name": "Dumbbell step-ups", "link": "https://youtu.be/l4AA5d5mInQ"} ,
        {"name": "Dumbbell hip thrusts", "link": "https://www.youtube.com/watch?v=SGZK0YZmEpU"} ,
        {"name": "Dumbbell glute bridges", "link": "https://youtu.be/iO9qZF0rzuE"} ,
        {"name": "Standing calf raises", "link": "https://www.youtube.com/watch?v=wxwY7GXxL4k"} ,
        {"name": "Hack squat machine", "link": "https://www.youtube.com/watch?v=0tn5K9NlCfo"} ,
        {"name": "Leg press", "link": "https://youtu.be/IZxyjW7MPJQ"} ,
        {"name": "Leg extension", "link": "https://www.youtube.com/watch?t=21s&v=YyvSfVjQeL0"} ,
        {"name": "Leg curl", "link": "https://www.youtube.com/watch?v=1Tq3QdYUuHs"} ,
        {"name": "Seated calf raise", "link": "https://youtu.be/JbyjNymZOt0"} ,
        {"name": "Standing barbell shoulder press", "link": "https://youtu.be/2yjwXTZQDDI"} ,
        {"name": "Seated barbell shoulder press", "link": "https://www.youtube.com/watch?t=4s&v=oBGeXxnigsQ"} ,
        {"name": "Push press", "link": "https://www.youtube.com/watch?v=ep30avTSMB0"} ,
        {"name": "Standing dumbbell shoulder press", "link": "https://www.youtube.com/watch?v=B-aVuyhvLHU"} ,
        {"name": "Seated dumbbell shoulder press", "link": "https://youtu.be/qEwKCR5JCog"} ,
        {"name": "Arnold press", "link": "https://youtu.be/6Z15_WdXmVw"} ,
        {"name": "Cuban press", "link": "https://www.youtube.com/watch?v=oQJoK0a3h44"} ,
        {"name": "Single arm dumbbell press", "link": ""} ,
        {"name": "Dumbbell lateral raises", "link": "https://youtu.be/3VcKaXpzqRo"} ,
        {"name": "Dumbbell front raises", "link": "https://youtu.be/-t7fuZ0KhDA"} ,
        {"name": "Dumbbell reverse flys", "link": "https://youtu.be/ttvfGg9d76c"} ,
        {"name": "Machine shoulder press", "link": "https://www.youtube.com/watch?t=2s&v=Wqq43dKW1TU"} ,
        {"name": "Machine lat raise", "link": ""} ,
        {"name": "Cable lat raise", "link": ""} ,
        {"name": "Cable front raise", "link": ""} ,
        {"name": "Cable reverse fly", "link": ""} ,
        {"name": "Cable face pulls", "link": "https://youtu.be/rep-qVOkqgk"} ,
        {"name": "Barbell bicep curl", "link": "https://youtu.be/QZEqB6wUPxQ"} ,
        {"name": "E-Z bar bicep curl", "link": "https://www.youtube.com/watch?v=zG2xJ0Q5QtI"} ,
        {"name": "Barbell preacher curl", "link": "https://www.youtube.com/watch?v=nbcgEmZ0Be4"} ,
        {"name": "Concentration curl", "link": "https://www.youtube.com/watch?v=Jvj2wV0vOYU"} ,
        {"name": "Dumbbell bicep curl", "link": "https://youtu.be/sAq_ocpRh_I"} ,
        {"name": "Hammer curls", "link": "https://youtu.be/zC3nLlEvin4"} ,
        {"name": "Incline bench dumbbell curl", "link": "https://youtu.be/soxrZlIl35U"} ,
        {"name": "Cable bicep curl", "link": "https://www.youtube.com/watch?t=25s&v=AsAVbj7puKo"} ,
        {"name": "Bicep machine curl", "link": "https://www.youtube.com/watch?v=M_uPvGrMx_o"} ,
        {"name": "Close-grip bench press", "link": "https://youtu.be/cXbSJHtjrQQ"} ,
        {"name": "Skull crushers", "link": "https://youtu.be/QXzhjRnYRT0"} ,
        {"name": "Tricep kickbacks", "link": "https://www.youtube.com/watch?v=bxPoVw8_khE"} ,
        {"name": "Standing overhead extension", "link": "https://youtu.be/jTQWYdWLvys"} ,
        {"name": "Seated overhead extension", "link": "https://youtu.be/YbX7Wd8jQ-Q"} ,
        {"name": "Machine tricep kickbacks", "link": "https://youtu.be/6sJsoI5-rIg"} ,
        {"name": "Cable tricep pushdown", "link": "https://youtu.be/2-LAMcpzODU"} ,
        {"name": "Dips", "link": "https://www.youtube.com/watch?t=2s&v=sM6XUdt1rm4"} ,
        {"name": "Barbell ab rollouts", "link": "https://www.youtube.com/watch?v=mIYuVpQF3N8"} ,
        {"name": "Side bends", "link": "https://www.youtube.com/watch?v=dL9ZzqtQI5c"} ,
        {"name": "Cable crunches", "link": "https://www.youtube.com/watch?v=HD7fH653SUw"} ,
        {"name": "Farmers walk", "link": "https://www.youtube.com/watch?v=rt17lmnaLSM"} ,
        {"name": "Sit up machine", "link": "https://www.youtube.com/watch?v=_O1xunCfYEM"} ,
        {"name": "Leg raises", "link": "https://www.youtube.com/watch?v=ttdkm6ESUjI"} ,
        {"name": "Knee raises", "link": "https://www.youtube.com/watch?v=KNzJ3GuIpB8"} ,
        {"name": "Ab crunches", "link": "https://www.youtube.com/watch?v=Xyd_fa5zoEU"} ,
        {"name": "Russian twists", "link": "https://www.youtube.com/watch?v=JyUqwkVpsi8"} ,
        {"name": "Plank hold", "link": "https://www.youtube.com/watch?v=Oi9pmH45W7A"} ,
        {"name": "Side plank hold", "link": "https://www.youtube.com/watch?v=RZpMQEFyzlA"}] 
}

export default exercises;