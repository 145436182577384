import "./styles/textfield.scss";

type TextFieldProps = {
    value: any,
    onChange: Function,
    color?: string,
    readonly?: boolean,
    type?: string,
    width?: string,
    style?: object,
    disabled?: boolean,
    placeholder?: string,
    children?: any
}

export default function TextField(props: TextFieldProps)
{
    return(<div className="textfield-root"
            style={Object.assign(
                {
                    width: props.width ? props.width : "100%",
                    color: props.color ? props.color : "white"
                }, 
                props.style ? props.style : {}    
            )}
            >
            <input 
                onChange={(event) => {
                    if(props.onChange)
                    {
                        return props.onChange(event)
                    }
                }}
                style={{
                    color: props.color ? props.color : "white"
                }}
                disabled={props.disabled ? props.disabled : false}
                value={props.value ? props.value : ""}
                type={props.type ? props.type : "text"}
                placeholder={props.placeholder ? props.placeholder : ""}
                readOnly={props.readonly ? props.readonly : false}
                ></input>
        </div>)
}