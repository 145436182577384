import './styles/login.scss';
import React, {useState, useContext} from 'react';

import TextField from "../components/TextField";
import Button from "../components/Button";
import { UserContextInterface, UserContext} from "../providers/userprovider";

type LoginProps = {
    
}

const Login = (props : LoginProps) => {

    const context : UserContextInterface = useContext(UserContext);

    const [renderError, setRenderError] = useState("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const login = () => {

        if(email.length === 0) {
           return setRenderError("Email field empty");
        }

        if(password.length === 0) {
            return setRenderError("Password field empty");
        }

        context.api.login(email, password)
            .then((data) => {
                context.login(data.token, data.email);
            })
            .catch((error) => {
                setRenderError(JSON.stringify(error));
            })

   }



   return(
    <div className="login-root">

        <header>
            <h2 onClick={()=>{
                window.location.href = "/"
            }}>Apollo</h2>
        </header>

        <div className="login-container">
            <label style={{width: "100%", textAlign: "left"}}>Email</label>
            <TextField
                value={email}
                width="100%"
                onChange={(event : any) => {setEmail(event.target.value)}}
            ></TextField>
            <label style={{width: "100%", textAlign: "left"}}>Password</label>
            <TextField
                value={password}
                width="100%"
                type="password"
                onChange={(event : any) => {setPassword(event.target.value)}}
            ></TextField>
            <div
                className="create-account-link"
            >Don't have an account. <a href="/register">Create Here</a></div>

            <div
                className="create-account-link"
            >Forget Password <a href="/forget-password">Click Here</a></div>
            
            <div
                className="error-message"
            >
                {renderError}
            </div>

            <div
                className="login-button-container"
            >
                <Button
                    width="100%"
                    color="white"
                    style={{
                        color: "black"
                    }}
                    onClick={() => login()}
                >Login</Button>
            </div>
        </div>

    </div>);
}

export default Login;