import React from 'react';
import "./styles/button.scss"

type ButtonProps = {
    onClick: Function,
    disabled?: boolean,
    className?: string,
    width?: string,
    height?: string,
    style?: object,
    color?: string,
    children?: any
}   

export default function Button(props : ButtonProps)
{
    return(
        <button 
            disabled={props.disabled ? props.disabled : false}
            onClick={(event) => {
                event.preventDefault()
                if(props.onClick)
                {
                    return props.onClick(event)
                }
            }}
            className={`button-root ${props.className ? props.className : {}}`}
            style={Object.assign({
                width: props.width ? props.width : "100%",
                height: props.height ? props.height : "",
                backgroundColor: props.color ? props.color : "#3F51B5"
            }, props.style ? props.style : {})}
        >
                
        {props.children}</button>
    )
}