import React, {createContext, useEffect, useState, useCallback} from 'react';
import {TOKEN_KEY, EMAIL_KEY} from "../utils/constants";
import ApolloAPI from "../api/index";

import loadingAnimation from "../assets/icons/loading.gif";

export interface UserContextInterface {
    isAuthenticated: Boolean,
    isLoading: Boolean,
    token: string | null,
    email: string | null,
    api : ApolloAPI,
    setLoading: Function,
    login: Function,
    logout: Function
}

export let UserContext = createContext({
    isAuthenticated : false,
    isLoading : true,
    token : null,
    email : null,
    api : new ApolloAPI(null, null),
    setLoading: () => {},
    login: () => {},
    logout: () => {}
} as UserContextInterface)

const api = new ApolloAPI(null, null);

const UserProvider = (props: any) =>
{
    const [isAuthenticated, setIsAuthenticated] = useState<Boolean>(false);
    const [email, setEmail] = useState<string | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<Boolean>(true);
    const [apolloAPI] = useState<ApolloAPI>(api)

    const checkAuthenticated = useCallback(() => {
        const token : string | null = localStorage.getItem(TOKEN_KEY);
        const email : string | null = localStorage.getItem(EMAIL_KEY);

        token ? setIsAuthenticated(true) : setIsAuthenticated(false)
        apolloAPI.email = email ? email : "";
        apolloAPI.token = token ? token : "";
        setToken(token);
        setEmail(email);
        setIsLoading(false);

    }, [setToken, setEmail, apolloAPI]) 

    const login = (token : string, email : string) => {

        localStorage.setItem(TOKEN_KEY, token)
        localStorage.setItem(EMAIL_KEY, email)

        apolloAPI.email = email ? email : "";
        apolloAPI.token = token ? token : "";

        setToken(token);
        setEmail(email)
        setIsAuthenticated(true);
    }

    const logout = () => {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(EMAIL_KEY);

        apolloAPI.email = "";
        apolloAPI.token = "";

        setToken(null);
        setEmail(null);
        setIsAuthenticated(false);
    }

    const setLoading = useCallback((isLoading : Boolean) => {
        setIsLoading(isLoading)
    }, [])

    useEffect(()=> {
        checkAuthenticated()
    }, [checkAuthenticated, setLoading])

    return(<UserContext.Provider value={{
        "isAuthenticated": isAuthenticated,
        "isLoading": isLoading,
        "token": token,
        "email": email,
        "api": apolloAPI,
        "setLoading": (isLoading : Boolean) => setLoading(isLoading),
        "login": (token : string, email : string) => login(token, email),
        "logout": () => logout()
    }}>
        <div style={{
            "display": isLoading ? "block" : "none",
            "position": "absolute", 
            "width": "100%",
            "height": "100%",
            "textAlign": "center",
            "boxSizing": "border-box",
            "paddingTop": "35%"
        }}>
            <img src={loadingAnimation} alt="Loading"></img>
        </div>
        <div
            style={{
                "display": isLoading ? "none" : "block"
            }}
        >
            {props.children}
        </div>
    </UserContext.Provider>)
}

export default UserProvider;