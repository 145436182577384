import React, {useContext} from 'react';

import "./styles/taskbarDesktop.scss";
import { useHistory } from 'react-router-dom'

import { UserContextInterface } from "../../providers/userprovider";
import { UserContext } from "../../providers/userprovider";


import UserProfileIcon from "../../assets/icons/user-profile.svg";

type TaskbarDesktopProps = {
    context: UserContextInterface,
    shouldRender: boolean,
    name: string
};

const menuOptions = ["Add Workout","Account", "Logout"]

const TaskbarDesktop = (props: TaskbarDesktopProps) => {

    const userContext : UserContextInterface = useContext(UserContext);
    const history = useHistory();

    const clickMenuOption = (value : string) => {
        switch(value)
        {
            case "Add Workout":
                history.push("/add-workout")
                break;
            case "Account":
                alert("Email: " + userContext.email)
                break;
            case "Logout":
                userContext.logout()
                break;
            default:
                
        }
    }

    return (
        <div    
            className="taskbar-desktop-root"
            style={{
                display: props.shouldRender ? "block" : "none",
                // width: MENU_WIDTH + "px",
                // transform: `translate(-${MENU_WIDTH+MENU_OFFSET}px)`,
                // top: "3.2vh"
            }}    
        >
            <div className="menu-header">
                    <img 
                        src={UserProfileIcon} alt="Profile"></img>
                    <div className="accountInfo">
                        {props.name}
                    </div>
            </div>

            <hr></hr>

            {menuOptions.map((value, index)=> {
                return(<div 
                    className="menu-options"
                    key={value}
                    onClick={() => clickMenuOption(value)}
                >
                    {value}
                </div>)
            })}
        </div>
    );
};

export default TaskbarDesktop;
