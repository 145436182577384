import React from "react";

import "./styles/modal.scss"

type ModalProps = {
    show: Boolean,
    onClose: Function,
    children: any
}

export default function Modal(props: ModalProps)
{
    return(
    <div 
        className={props.show ? "modal display" : "modal no-display"}
        >
        
        <section className="modal-main" onClick={() => {}}>
            {props.children}
            <hr></hr>
            <div 
                className="close-button"
                onClick={() => {props.onClose()}}>Close
            </div>
        </section>
    </div>
    )
}