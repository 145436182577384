import React from 'react';

import UserProvider, {UserContext, UserContextInterface} from "./providers/userprovider";
import { BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import './app.scss';

import AddWorkout from "./pages/AddWorkout";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ResetPassword from "./pages/ResetPassword";
import ForgetPassword from "./pages/ForgetPassword";
import VerifyEmail from "./pages/VerifyEmail";
import EditWorkout from './pages/EditWorkout';
import Landing from './pages/Landing';

function App() {

  const PUBLIC_DEFAULT_URL = "/"
  const PRIVATE_DEFAULT_URL = "/dashboard"

  const privateRoutes = (context : UserContextInterface, route : string) => {

    if(!context.isAuthenticated)
    {
      return <Redirect to={PUBLIC_DEFAULT_URL}></Redirect>
    }

    switch(route) {
      case "DASHBOARD":
        return <Dashboard/>
      case "ADD_WORKOUT":
        return <AddWorkout context={context}/>
      case "EDIT_WORKOUT":
        return <EditWorkout context={context} />
      default:
        return <Dashboard/>
    }
  }

  const publicRoutes = (context : UserContextInterface, route : string) => {
    if(context.isAuthenticated)
    {
      return <Redirect to={PRIVATE_DEFAULT_URL}></Redirect>
    }

    switch(route) {
      case "LOGIN":
        return <Login/>
      case "FORGET_PASSWORD":
        return <ForgetPassword/>
      case "REGISTER":
        return <Register context={context}/>
      case "LANDING":
        return <Landing></Landing>
      default:
        return <Register context={context}/>
    }
  }

  return (
    <div className="app-root">
      <UserProvider>
        <UserContext.Consumer>
          {context => {
            return(
              <BrowserRouter>
                <Switch>
                  <Route exact path="/edit-workout" component={() => {return privateRoutes(context, "EDIT_WORKOUT")}}/>
                  <Route exact path="/dashboard" component={() => {return privateRoutes(context, "DASHBOARD")}} />
                  <Route exact path="/add-workout" component={() => {return privateRoutes(context, "ADD_WORKOUT")}} />

                  <Route exact path="/" component={() => {return publicRoutes(context, "LANDING")}}/>
                  <Route exact path="/register" component={() => {return publicRoutes(context, "REGISTER")}}/>
                  <Route exact path="/login" component={() => {return publicRoutes(context, "LOGIN")}} />
                  <Route exact path="/forget-password" component={() => {return publicRoutes(context, "FORGET_PASSWORD")}}/>

                  {/* Exists in both private and public routes */}
                  <Route exact path="/reset-password/:slug" component={() => {return (<ResetPassword/>)}}></Route>
                  <Route exact path="/verify-email/:slug" component={() => {return <VerifyEmail/>}}/>

                  {/* No routes defined */}
                  <Redirect to="/"></Redirect>
                </Switch>
              </BrowserRouter>
            )
          }}
        </UserContext.Consumer>
      </UserProvider>
    </div>
  );
}



export default App;
