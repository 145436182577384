import { BASE_URI } from "../utils/constants";

import { 
    loginRoute,
    registerRoute,
    verifyRoute,
    submitPasswordResetRoute,
    changePasswordRoute
 } from "./routes";

export const login = (email: string, password: string) : Promise<any> =>
{
    return new Promise((resolve, reject) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        const payload = `{
            "username": "${email}",
            "password": "${password}"
        }`;
        
        let requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: payload,
        };
        
        fetch(`${BASE_URI}/account/${loginRoute}`, requestOptions)
            .then((response) =>  {
                return response.json()
            })
            .then((json) => {

                //Check if response is an error.
                if(json.error) {
                    return reject(json.error)
                }

                return resolve(json.success);
            })
            .catch((error) => {
                reject({error});
            });
    });
}

export const register = (accountName : string, email : string, password : string, password2: string) : Promise<any> => {

    return new Promise((resolve, reject) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        const payload = `{
            "name": "${accountName}",
            "email": "${email}",
            "password": "${password}",
            "password2": "${password2}"
    
        }`; // template literal
    
        const requestOptions : any = {
            method: 'POST',
            headers: myHeaders,
            mode: 'cors',
            body: payload,
        };
    
        fetch(`${BASE_URI}/account/${registerRoute}`, requestOptions) 
            .then((response) => {
                return response.json()
            })
            .then((json : any) => {
    
                if(json.error) {
                    return reject(json.error);
                }
                
                return resolve(json.success);

            })
            .catch(error => reject(error));
    })

}

export const verifyLink = (verificationCode : string) : Promise<any> => {
    return new Promise((resolve, reject) => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const payload = {
            "verification_code": verificationCode
        }

        const requestOptions = {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(payload),
        }

        fetch(`${BASE_URI}/account/${verifyRoute}`, requestOptions)
            .then((res) => {
                return res.json()
            })
            .then((json) => {
                if(json.error) {
                    reject(json.error);
                }
                
                resolve(json.success);
            })
            .catch((error) => {
                reject(error);
            })
    })
}

export const submitPasswordReset = (email : string) : Promise<any> => {
    return new Promise((resolve, reject) => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const payload = `{
            "email": "${email}"
        }`

        const requestOptions = {
            method: "POST",
            headers: headers,
            body: payload
        }

        fetch(`${BASE_URI}/account/${submitPasswordResetRoute}`, requestOptions)
            .then((res) => { return res.json() })
            .then((json) => {
                if(json.error) {
                    reject(json.error)
                }

                resolve(json.success);
            })
    })
}

export const changePassword = (identifier: string, password : string, password2 : string) : Promise<any> => {
    return new Promise((resolve, reject) => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json")

        const payload = `{
            "identifier": "${identifier}",
            "password": "${password}",
            "password2": "${password2}"
        }`;

        const requestOptions = {
            method: "POST",
            headers: headers,
            body: payload
        }

        fetch(`${BASE_URI}/account/${changePasswordRoute}`, requestOptions)
            .then((res) => {
                return res.json()
            })
            .then((json) => {
                if(json.error) {
                    reject(json.error);
                }

                if(json.success) {
                    resolve(json.success)
                }

                reject("Something went wrong with your request");
            })
            .catch((error) => {
                reject(error);
            })
    })
}
