import React, {useState, useEffect} from 'react';
import "./styles/addset.scss";

import Button from "../components/Button";
import TextField from "../components/TextField";
import {AddSetWorkoutSet} from "../utils/types";

type AddSetProps = {
    set : AddSetWorkoutSet,
    number: number,
    onChangeReps: Function,
    onChangeWeight: Function,
    onChangeBodyweight: Function,
    onChangeNote: Function,
    onDelete: Function,
    children?: any
}

const REP_STEP : number = 1;
const WEIGHT_STEP : number = 5.0;
const ANIMATION_TIME_NUM : number = 0.3;
const ANIMATION_TIME_TIME : string = ANIMATION_TIME_NUM + "s";


const customButtonStyle = {
    background: "transparent",
    border: "solid",
    borderWidth: "1px",
    borderRadius: "10%"
}

const AddSet = (props : AddSetProps) => 
{

    const [isRemoving, setIsRemoving] = useState<boolean>(false);

    useEffect(() => {
        if(isRemoving)
        {
            setTimeout(() => {
                props.onDelete(props.set.id)
            }, (ANIMATION_TIME_NUM * 1000) - 20)

        }
    }, [props, isRemoving])

    const changeReps = (event : any) => {

        let reps = props.set.reps;

        if(event.target.value.length === 0)
        {
            return props.onChangeReps("", props.number - 1)
        }

        switch (isNaN(event.target.value)){
            case true:
                return;
            case false:
                reps = event.target.value;
                break;
            default:
                
        }

        return props.onChangeReps(reps, props.number - 1)
    }

    const changeWeight = (event : any) => {

        let weight = props.set.weight;

        if(event.target.value.length === 0)
        {
            return props.onChangeWeight("", props.number - 1)
        }

        switch (isNaN(event.target.value)){
            case true:
                //Entered letter. Do nothing
                break;
            case false:
                weight = event.target.value
                break;
            default:
        }

        return props.onChangeWeight(weight, props.number -1);
    }

    const buttonChangeReps = (status : string) => {

        if(props.set.reps.length === 0)
        {
            return props.onChangeReps(1, props.number-1)
        }

        let reps : number = parseInt(props.set.reps)

        switch(status)
        {
            case "+":
                reps += REP_STEP;
                break;
            case "-":
                reps = reps > 0 ? reps - REP_STEP : reps;
                break;
            default:
                //None
        }

        props.onChangeReps(reps.toString(), props.number - 1)
    }

    const buttonChangeWeight = (status : string) => {

        if(props.set.weight.length === 0)
        {
            return props.onChangeWeight(WEIGHT_STEP, props.number-1)
        }

        let weight : string = props.set.weight;

        switch(status)
        {
            case "+":
                weight = (parseFloat(weight) + WEIGHT_STEP).toFixed(2);
                break;
            case "-":
                weight = parseFloat(weight) - WEIGHT_STEP >= 0.0 ? (parseFloat(weight) - WEIGHT_STEP).toFixed(2) : weight;
                break;
            default:
                //None
        }

        props.onChangeWeight(weight.toString(), props.number -1);
    }

    const changeNotes = (event : any) => {
        props.onChangeNote(event.target.value, props.number - 1)
    }

    const changeBodyweight = () => {
        props.onChangeWeight(props.set.bodyweight ? "0" : "-1", props.number - 1)
        props.onChangeBodyweight(!props.set.bodyweight, props.number - 1)
    }

    return (
        <div 
            className="add-set-root"
            style={
                isRemoving ? {
                    animationDuration: ANIMATION_TIME_TIME,
                    animationName: "decrease",
                } : {
                    animationDuration: ANIMATION_TIME_TIME,
                    animationName: "expand_animation"
                }}
        >
            <div className="header-container">
                <label><b>Set {props.number}</b></label>
                <div 
                    className="deleteButton"
                    onClick={() => setIsRemoving(true)}    
                >X</div>
            </div>

            <div className="add-reps-container">
                <TextField 
                    value = {props.set.reps.toString()}
                    onChange={(event : any) => changeReps(event)}
                    width="100%" 
                    placeholder="Reps*"
                    ></TextField>
                <Button 
                    width="40px"
                    onClick={(event : any) => buttonChangeReps("+")}  
                    style={Object.assign({
                        margin: "0px 10px 0px 20px",
                    }, customButtonStyle)}  
                >+</Button>
                <Button 
                    width="40px"
                    onClick={(event : any) => buttonChangeReps("-")}
                    style={customButtonStyle}
                >-</Button>
            </div> <br/>
            <div 
                className="add-weight-container"
                style={{
                    color: props.set.bodyweight ? "grey" : "white"
                }}
            >
                <TextField
                    value= {props.set.weight.toString() === "-1" ? "" : props.set.weight.toString()}
                    onChange={(event : any) => changeWeight(event)} 
                    disabled={props.set.bodyweight}
                    placeholder="Weight*"></TextField>
                <Button 
                    width="40px"
                    onClick={() => buttonChangeWeight("+")}    
                    disabled={props.set.bodyweight}
                    style={Object.assign({
                        margin: "0px 10px 0px 20px",
                        color: props.set.bodyweight ? "grey" : "white"
                    }, customButtonStyle)}  
                >+</Button>
                <Button 
                    width="40px"
                    onClick={() => buttonChangeWeight("-")}
                    disabled={props.set.bodyweight}
                    style={Object.assign({
                        color: props.set.bodyweight ? "grey" : "white" 
                    }, customButtonStyle)}
                >-</Button>
            </div>

            <br></br>

            <div
                
            >
                <TextField
                    value= {props.set.note}
                    onChange={(event : any) => changeNotes(event)} 
                    disabled={false}
                    placeholder="Note"></TextField>
            </div>
            <div className="bodyweight-container">
                <label>Bodyweight: </label>
                <input 
                    checked={props.set.bodyweight}
                    type="checkbox"
                    onClick={() => changeBodyweight()}
                    ></input>
            </div>
        </div>
    )
}

export default AddSet;