import React, {useRef, useState} from 'react'
import "./styles/datepicker.scss";

type DatePickerProps = {
    onChange: Function,
    value?: Date | null,
    children?: any,
    disabled?: boolean,
}

const DatePicker = (props : DatePickerProps) => {
    const dateInput = useRef<HTMLInputElement>(null);

    const [date, setDate] = useState<Date | null>(props.value ? props.value : null);

    const onChangeDate = (event : any) => {

        if(event.target.value === null)
        {
            
        }

        if(event && props.onChange)
        {
            setDate(new Date(event.target.value + "T00:00:00"))
            props.onChange(new Date(event.target.value + "T00:00:00"))
        }
    }

    const displayDateString = () => {

        //Displays ISO string but rearranges to form mm-dd-yyyy instead of yyyy-mm-dd.

        try {
            return date ? date.toISOString().substring(5, 10) + "-" + date.toISOString().substring(0, 4) : "";
        }
        catch(error)
        {
            //Most likely clearing with range error.
            if(props.onChange)
            {
                setDate(null)
                props.onChange(null)
            }
            return "Date";
        }
    

    }


    return(<div 
        className="date-picker-root"
        style={{
            display: props.disabled ? "none" : "block"
        }}
        >
            <div className="text-container">

    
                <div className="calendar-text">
                    <p
                        style={{
                            color: date && props.value ? "white" : (date && props.value !== null ? "white" : "grey")
                        }}
                    >
                        {date && props.value ? displayDateString() : (date && props.value !== null ? displayDateString() : "Date")}
                    </p>
                </div>
                <div className="calendar-icon">
                    <input
                            type="date"
                            ref={dateInput}
                            onChange={(event) => {onChangeDate(event)}}
                            disabled={props.disabled ? props.disabled : false}
                        >
                        
                    </input>  
                </div>

            </div>
    </div>)
} 

export default DatePicker;