import React from "react"

import WorkoutContainer from "../../components/WorkoutContainer";
import { useHistory } from 'react-router-dom';

import { UserContextInterface } from "../../providers/userprovider";
import { Workout } from "../../utils/types";
import { Filter } from "./Dashboard";

type WorkoutDisplayProps = {
    context: UserContextInterface
    filterOptions: Filter,
    onDeleteWorkout: Function,
    sortedDateList: Array<any>,
    onScroll: Function
}

const WorkoutDisplay = (props : WorkoutDisplayProps) => {
    const history = useHistory();

    const filterDateByName = (workoutObj : any) => {

        if(props.filterOptions.name.length === 0) {
            return true;
        }

        const workouts : Array<Workout> = workoutObj?.workout;

        for(let i = 0; i < workouts.length; i++) {
            if(workouts[i].name.toLowerCase().includes(props.filterOptions.name.toLowerCase()) ) {
                return true;
            }
        }

        return false;
    }

    return(
        //SCSS is in dashboard scss
        <div 
            className="workouts"
            onScroll={() => {props.onScroll()}}   
             
        >
            {/* When list is empty */}
            {props.sortedDateList.length === 0 ? 
                <div className="empty-container">
                    Empty List. Get started by adding an workout.
                </div> 
                    : 
                <div>
                </div>}

            {props.sortedDateList
            .filter((workoutObj, index) => {
                const filterDate = props.filterOptions.date?.getTime()
                const workoutDate = new Date(workoutObj.date + "T00:00:00").getTime()
                const hasFilterName = filterDateByName(workoutObj)

                if(props.filterOptions.date === null && hasFilterName)
                    return true;
                
                if(filterDate === workoutDate && hasFilterName)
                    return true;
                
                return false
            })
            .map((workoutObj, index) => {
                const date = workoutObj?.date;
                const workouts = workoutObj?.workout;

                return (<div
                    className="day-container"
                    key={index}
                >
                    <div
                        className="date"
                    >{date}
                    </div>

                    {workouts
                    .filter((workout : Workout, index : number) => {
                        if(props.filterOptions.name.length === 0) {
                            return true;
                        }

                        if(workout.name.toLowerCase().includes(props.filterOptions.name.toLowerCase())) {
                            return true;
                        }

                        return false;
                    })
                    .map((workout : Workout, index : number) => {
                            return <WorkoutContainer
                                key={index}
                                name={workout.name}
                                sets={workout.sets}
                                workoutNote={workout.workout_note}
                                workoutId={workout.id}
                                onDeleteWorkout={(workoutId : number) => props.onDeleteWorkout(workoutId)}
                                extendDefault={false}
                                onEdit={(event : any) => {

                                    // Allow edit of workout
                                    history.push({
                                        pathname: '/edit-workout',
                                        state: {
                                            workoutId: workout.id,
                                            workoutName: workout.name,
                                            accomplishedDate: workout.accomplished_date,
                                            workoutNote: workout.workout_note,
                                            sets: workout.sets
                                        }
                                    })
                                }}
                            >
                            </WorkoutContainer>
                        })
                    }
                </div>)
            })}
        </div>)
}

export default WorkoutDisplay;