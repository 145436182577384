import React, {useEffect, useState, useCallback, useContext} from 'react';
import "./styles/verifyEmail.scss";
import {useParams} from 'react-router-dom'; 

import { UserContext, UserContextInterface  } from "../providers/userprovider";

type VerifyEmailProps = {
    
}

const VerifyEmail = ((props : VerifyEmailProps) => {

    const context : UserContextInterface = useContext(UserContext);

    const { slug } : any = useParams();
    const [identifier] = useState<string>(slug);
    const [message, setMessage] = useState<string>("Loading");

    const deleteVeri = useCallback(() => {

        context.api.verifyLink(identifier)
            .then((res) => {
                setMessage("Email verified");
            })
            .catch((error) => {
                console.log(error);
            });
    }, [identifier, context.api])

    useEffect(() => {

        const clock = setTimeout(() => {
            deleteVeri()
        }, 100)
        
        return function cleanup()
        {   
            clearTimeout(clock);
        }


    }, [deleteVeri])

    return(<div className="verify-email-root">
        <header>
            <h2>Verify Email</h2>
        </header>

        <div className="message">
            {message}
            <br></br>
            <br></br>
            <a href={context.isAuthenticated ? "/dashboard" : "/"}>Return To Account</a>
        </div>
    </div>)
}) 

export default VerifyEmail;