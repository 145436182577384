import React, {useState, useContext} from 'react';
import {useParams} from "react-router-dom";

import TextField from "../components/TextField";
import Button from "../components/Button";

import { UserContext, UserContextInterface  } from "../providers/userprovider";

import "./styles/resetPassword.scss";

const ResetPassword = (props: any) => {

    const { slug } : any = useParams();

    const context : UserContextInterface = useContext(UserContext);

    const [resetSucessfully, setResetSucessfully] = useState<boolean>(false)
    const [password, setPassword] = useState<string>("")
    const [password2, setPassword2] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const changePassword = () => {
        if(password.length === 0) {
            return setErrorMessage("Password field empty")
        }

        if(password2.length === 0) {
            return setErrorMessage("Confirm password field empty")
        }

        context.api.changePassword(slug, password, password2)
            .then((res) => {
                setResetSucessfully(true);
            })
            .catch((error) => {
                setErrorMessage(JSON.stringify(error))
            })

    }

    if(resetSucessfully)
    {
        return <div className="forgetpassword-root">
            <header>
                <h2>Successfully Reset Password</h2>
            </header>

            <div style={{textAlign: "center"}}>
                <a href="/login">Access Your Account</a>
            </div>
        </div>
    }

    return <div className="forgetpassword-root">

        <header>
            <h2>Enter a new password</h2>
        </header>

        <div className="form-options">
            <TextField
                value={password}
                onChange={(event : any) => setPassword(event.target.value)}
                placeholder="New Password"
                type="password"
            >

            </TextField>

            <TextField
                value={password2}
                onChange={(event : any) => setPassword2(event.target.value)}
                placeholder="Confirm New Password"
                type="password"
            >

            </TextField>

            <div className="error-box">
                {errorMessage}
            </div>

            <Button
                color="white"
                style={{
                    color: "black"
                }}
                onClick={(event : any) => changePassword()}
            >
                Change Password
            </Button>
        </div>


    </div>
}

export default ResetPassword;