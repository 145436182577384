import './styles/dashboard.scss'
import React, {useState, useEffect, useCallback, useContext} from "react"

import DashboardFilter from "./DashboardFilter";
import TaskbarMobile from "./TaskbarMobile";
import WorkoutDisplay from "./WorkoutDisplay";
import TaskbarDesktop from "./TaskbarDesktop";
import Button from "../../components/Button";
import ExcelDownload from "./ExcelDownload";

import loadingAnimation from "../../assets/icons/loading.gif";
import DumbbellIcon from "../../assets/icons/dumbbell.png";

import { useHistory } from 'react-router-dom';
import { UserContextInterface } from "../../providers/userprovider";
import { UserContext } from "../../providers/userprovider";
import { Workout } from "../../utils/types";

type DashboardProps = {
  
}

export type Filter = {
    name: string,
    date: Date | null
}

const Dashboard = (props : DashboardProps) => {

    const context : UserContextInterface = useContext(UserContext);

    const [sortedDateList, setSortedDateList] = useState<Array<any>>([]);
    const [name, setName] = useState<string>("");
    const [filterOptions, setFilterOptions] = useState<Filter>({
        "name": "",
        "date": null
    })
    const [isLoading, setIsLoading] = useState<Boolean>(true);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isScrolling, setIsScrolling] = useState<boolean>(false);

    const history = useHistory();
    let scrollTimer : any;

    const onDeleteWorkout = (workoutId : number) => {

        context.api.deleteWorkout(workoutId)
            .then(data => {
                if(data) {
                    loadData()
                }

                return;
            })
            .catch(err => {
                console.log(err)
            })
    }


    const loadData = useCallback(() => {
        function rearrangeWorkoutList(workoutList : Array<Workout>) {

            //Keys : Dates. Value : Array of workouts
            let workoutDateObject = {} as any;
            
            //Place each workout in an array of date objects. 
            /**
             * Example
             * {
             *  "2021-08-10": [Object<Workout>, Object<Workout>],
             *  "2021-03-10": [Object<Workout>]
             * }
             */
            workoutList.forEach((workout) => {
                const date = workout?.accomplished_date.split(",")[0];
    
                if(workoutDateObject[date])
                {
                    workoutDateObject[date].push(workout);
                }
                else
                {
                    workoutDateObject[date] = [];
                    workoutDateObject[date].push(workout);
                }
            })
    
            let sorted = Object.keys(workoutDateObject).sort().map((date) => {
                return {
                    "date": date,
                    "workout": workoutDateObject[date]
                }
            })
    
            setSortedDateList(sorted.reverse())
        }

        setIsLoading(true);

        context.api.getAccountInfo()
            .then((data) => {
                setName(data.name);
                rearrangeWorkoutList(data.workout);
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
                alert(JSON.stringify(error));
                setIsLoading(false)
            })

    }, [setSortedDateList, context])

    useEffect(() => {
        loadData();
        setIsMobile(window.innerWidth < 768)
    }, [loadData, context])

    window.addEventListener('resize', ()=> {
        setIsMobile(window.innerWidth < 768);
    })

    const onScroll = () => {
        clearTimeout(scrollTimer);
        setIsScrolling(true);

        scrollTimer = setTimeout(() => {
            setIsScrolling(false);
        }, 500)


    }

    if(isLoading)
    {
        return(<div style={{
            "display": isLoading ? "block" : "none",
            "position": "absolute", 
            "width": "100%",
            "height": "100%",
            "textAlign": "center",
            "boxSizing": "border-box",
            "paddingTop": "35%"
        }}>
            <img src={loadingAnimation} alt="Loading"></img>
        </div>)
    }

    return (
        <div 
            className="dashboard-root"
        >
            
            <div className="header">
                <TaskbarMobile
                    shouldRender={isMobile}
                    name={name}
                ></TaskbarMobile>

                <TaskbarDesktop
                    context={context}
                    shouldRender={!isMobile}
                    name={name}
                ></TaskbarDesktop>
                <div>{name}'s Workouts</div>
            </div>

            <div className="filter-container">
                <ExcelDownload
                        name={name}
                ></ExcelDownload>
                
                <DashboardFilter
                    onChange={(obj : {
                        name : string,
                        date: Date
                    }) => {
                        setFilterOptions(obj)
                    }}
                ></DashboardFilter>
            </div>

            <hr></hr>

            <Button
                    onClick={() => {history.push('/add-workout')}}
                    width={"70px"}
                    height={"70px"}
                    style={{
                        display: isMobile ? "block" : "none",
                        position: "absolute",
                        backgroundColor: "#E5E4E2",
                        borderRadius: "100px",
                        right: "30px",
                        bottom: "70px",
                        opacity: isScrolling ? "0%" : "100%",
                        transition: "opacity 0.3s ease",
                    }}
            >
                <img 
                    style={{
                        width: "30px",
                        height: "30px"
                    }}
                    src={DumbbellIcon} 
                    alt="Add Workout"></img>
                    <br></br>
                    <div style={{
                        color: "black",
                        fontSize: "16px"
                    }}>+</div>
            </Button>

            <WorkoutDisplay
                context={context}
                filterOptions={filterOptions}
                onDeleteWorkout={(workoutId : number) => {onDeleteWorkout(workoutId)}}
                sortedDateList={sortedDateList}
                onScroll={() => {onScroll()}}
            ></WorkoutDisplay>

        </div>
    );
}

export default Dashboard;