import React, {useState, useContext} from "react";
import { useHistory, useLocation } from 'react-router-dom'

import Button from "../components/Button"
import TextField from "../components/TextField";
import AddSet from "../components/AddSet";
import DatePicker from "../components/DatePicker";
import Modal from "../components/Modal";
import SelectExercise from "../components/SelectExercise";

import { UserContextInterface, UserContext} from "../providers/userprovider";
import { AddSetWorkoutSet } from "../utils/types";

type EditWorkoutProps = {
    context: UserContextInterface,
}

type LocationState = {
    workoutId: string,
    workoutName: string,
    accomplishedDate: string,
    workoutNote: string,
    sets: Array<AddSetWorkoutSet>
}

/**
 * Edit Workout Page
 * 
 * Very similar to AddWorkout. 
 * 
 * @param props 
 * @returns 
 */
const EditWorkout = (props: EditWorkoutProps) => {

    /**
     * Parse date into appriopriate string
     */
    const parseDate = () : Date =>
    {
        const date = location.state.accomplishedDate.replace(",", "");

        if(navigator.userAgent.toLowerCase().indexOf('safari') !== -1)
        {
            //Safari
            return new Date(date.replace(/-/g, "/"));
        }

        return new Date(date)
    }

    const history = useHistory()
    const location = useLocation<LocationState>()
    const context = useContext(UserContext);

    const [showModal, setShowModal] = useState<boolean>(false);

    const [workoutName, setWorkoutName] = useState<string>(location.state.workoutName);
    const [accomplishedDate, setAccomplishedDate] = useState<Date | null>(parseDate());
    const [workoutNote, setWorkoutNote] = useState<string>(location.state.workoutNote);
    const [sets, setSets] = useState<Array<AddSetWorkoutSet>>(location.state.sets);

    const getRandomId = () : string => {
        let sameId = true;
        let randomString = "";

        while(sameId)
        {
            randomString = (Math.random() + 1).toString(36);
            sameId = false;
            
            for(let i = 0; i < sets.length; i++)
            {
                if (sets[i].id === randomString) { sameId = true }
            }

        }

        return randomString;
    }

    /**
     * Fires when an user clicks on add set
     * @param event Click event
     */
    const addSetClick = (event : any) => {
        event.preventDefault()

        const previousReps = sets[sets.length-1]?.reps;
        const previousWeight = sets[sets.length-1]?.weight;
        const previousBody = sets[sets.length-1]?.bodyweight;

        setSets(sets.concat({
            "id": getRandomId(),
            "reps": previousReps ? previousReps : "",
            "weight": previousWeight ? previousWeight : "",
            "note": "",
            "bodyweight": previousBody ? previousBody : false
        }))
    }

    /**
     * Change a specific set's reps value
     * @param {String} reps 
     * @param {Number} index - The set index
     */
    const changeSetReps = (reps : string, index: number) => {
        let setsLocal = sets.concat([]);
        setsLocal[index]["reps"] = reps;

        setSets(setsLocal);
    }

    /** 
    * Change a specific set's weight value
    * @param {String} weight
    * @param {Number} index - The set index
    */
    const changeSetWeight = (weight : string, index : number) => {
        let setsLocal = sets.concat([]);
        setsLocal[index]["weight"] = weight;

        setSets(setsLocal);
    }

        /**
     * Change if a set is bodyweight or not
     * @param {Boolean} bodyweight 
     * @param {Number} index 
     */
    const changeSetBodyweight = (bodyweight : boolean, index : number) => {
        let setsLocal = sets.concat([]);
        setsLocal[index]["bodyweight"] = bodyweight;

        setSets(setsLocal);
    }

    /**
     * Change the note of a set
     * @param note The note for the specific set
     * @param index The index of the set
     */
    const changeSetNote = (note : string, index : number) => {
        let setsLocal = sets.concat([]);
        setsLocal[index]["note"] = note;

        setSets(setsLocal);
    }
    
    /**
     * Delete set from add workout
     * @param index Index of set
     */
    const deleteSet = (id : string) => {
        let setsLocal = sets.concat([]);
        let index = -1;
        for(let i = 0; i < setsLocal.length; i++)
        {
            if(setsLocal[i].id === id) { index = i; }
        }
        setsLocal.splice(index, 1);

        setSets(setsLocal);
    }

    /**
     * Helper function for submit workout. Checks if data is valid. Returns appriopriate json
     * if so.
    */
    const checkDataValid = () => {

        for(let i = 0; i < sets.length; i++)
        {
            if ((sets[i]["weight"].length === 0 || sets[i]["reps"].length === 0) && sets[i]["bodyweight"] === false)
            {
                alert("Fields in reps or weights missing or invalid.")
                return false
            }
        }

        if(sets.length === 0)
        {
            alert("Please add sets to your workout");
            return false
        }

        if(workoutName.length === 0)
        {
            alert("Please enter an workout name")
            return false;
        }

        if(accomplishedDate === null)
        {
            alert("Please enter a date")
            return false;
        }

        return {
            "name": workoutName,
            "accomplished_date": accomplishedDate.toISOString(),
            "workout_note": workoutNote,
            "sets": sets
        }
    }

    /**
     * TODO: Link up with api backend. 
     * 
     * Submits workout to backend.
     */
    const submitWorkout = () => {
        let submit_json = checkDataValid(); 
    
        if(submit_json) {

            context.api.updateWorkout(parseInt(location.state.workoutId), submit_json)
                .then((data) => {
                    history.push("/");
                })
                .catch((error) => {
                    alert(JSON.stringify(error));
                })
        }

    }

    return (
        <div className="edit-root">
            <div className="edit-header">
                <p 
                    className="back"
                    onClick={() => {
                        history.push("/")
                    }}    
                > <b>←</b> </p>
                <p className="text"> <b>Edit Workout</b></p>
            </div>

            <div className="basic-info-container">
                <Modal 
                    show={showModal} 
                    onClose={() => setShowModal(!showModal)}
                >
                    <SelectExercise
                        selectExercise={(exercise : string) => {
                            setWorkoutName(exercise)
                            setShowModal(!showModal)
                        }}
                    />
                </Modal>

                <div onClick={() => {setShowModal(!showModal)}}>
                    <div className="label">Workout Name *</div>
                    <TextField
                        placeholder="Workout Name"
                        value={workoutName}
                        onChange={(event : any) => setWorkoutName(event.target.value)}
                        width="100%"
                        readonly={true}
                    >

                    </TextField>
                </div>

                <div className="label">Accomplished Date *</div>
                <DatePicker
                    onChange={(date : Date) => {setAccomplishedDate(date)}}
                    disabled={showModal}
                    value={parseDate()}
                >
                </DatePicker>

                <div className="label">Workout Note</div>
                <TextField
                    placeholder="Workout Note"
                    value={workoutNote}
                    onChange={(event : any) => setWorkoutNote(event.target.value)}
                    width="100%"
                >

                </TextField>
            </div>

            <hr></hr>

            <div className="sets-container">
                {sets.map((set, index) => {
                    return (<AddSet
                            number={index+1}
                            key={set.id}
                            set={set}
                            onChangeReps={(reps : string, index : number) => changeSetReps(reps,index)}
                            onChangeWeight={(reps : string, index : number) => changeSetWeight(reps,index)}
                            onChangeBodyweight={(bodyweight : boolean, index : number) => changeSetBodyweight(bodyweight, index)}
                            onChangeNote={(note : string, index : number) => changeSetNote(note, index)}
                            onDelete={(id : string) => deleteSet(id)}
                        >
                        
                        </AddSet>)
                })}
                <div 
                    className="add-set-container"
                    onClick={(event) => addSetClick(event)}
                >
                    + <br></br>
                    Add Set
                </div>
            </div>

            <div className="submit-container">
                <Button 
                    onClick={() => submitWorkout()}
                    style={{
                        "margin": "0px 0px 0px 0px",
                        "background": "transparent",
                        "border": "solid",
                        "borderWidth": "1px"
                    }}
                >Edit Workout</Button>
            </div>

        </div>
    );

}

export default EditWorkout;