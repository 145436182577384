import React, {useState} from "react";

import Button from "../../components/Button";
import DatePicker from "../../components/DatePicker";
import TextField from "../../components/TextField";

import FilterIcon from "../../assets/icons/filter.png";
import "./styles/dashboardFilter.scss"

type DashboardFilterProps = {
    onChange: Function
}

const DashboardFilter = (props : DashboardFilterProps) => {

    const [triggerDropdown, setTriggerDropdown] = useState<boolean>(false);
    const [filterDate, setFilterDate] = useState<Date | null>(null);
    const [filterName, setFilterName] = useState<string>("")

    const clearFilter = () => {
        setFilterDate(null);
        setFilterName("");

        props.onChange({
            "date": null,
            "name": ""
        })
    }


    return(<div className="dashboard-filter-root">
        <div className="filter-toggle-container">
            <Button
                color=""
                width="70px"
                style={{
                    "margin": "0px 0px 0px 0px",
                    "background": "transparent",
                    "padding": "0px 0px 0px 0px"
                }}
                onClick={() => {setTriggerDropdown(!triggerDropdown)}}
            >Filters
            <img
                style={{
                    width: "12px",
                    height: "12px",
                    transform: triggerDropdown ? "rotate(180deg)" : "rotate(0deg)" 
                }}
                src={FilterIcon} 
                alt="Filter Icon"></img>
            </Button>

            <Button
                width="70px"
                style={{
                    "margin": "0px 0px 0px 0px",
                    "background": "transparent",
                    "padding": "0px 0px 0px 0px",
                    "display": triggerDropdown ? "block" : "none"
                }}
                onClick={() => {clearFilter()}}
            >Clear</Button>

        </div>

        <div
            className="filter-content"
            style={{
                maxHeight: triggerDropdown ? "100px" : "0px"
            }}
        >
            <DatePicker
                onChange={(date : Date) => {
                    setFilterDate(date)
                    props.onChange({
                        "date": date,
                        "name": filterName
                    })
                }}
                value={filterDate}
            ></DatePicker>

            <TextField
                value={filterName}
                onChange={(event : any) => {
                    setFilterName(event.target.value)
                    props.onChange({
                        "date": filterDate,
                        "name": event.target.value
                    })
                }}
                placeholder={"Workout Name"}
            ></TextField>
        </div>
    </div>)
}

export default DashboardFilter;