import {
    getAccountInfo,
    createWorkout,
    updateWorkout,
    deleteWorkout,
    excelDownload 
} from "./workouts";

import {
    login,
    register,
    verifyLink,
    submitPasswordReset,
    changePassword
} from "./authentication";

/**
 * ApolloAPI Class. Usually instantiated in user provider.
 */


export default class ApolloAPI {

    //Private fields
    _token: string;
    _email: string;

    constructor(token?: string | null, email?: string | null) {
        console.log("Invoke constructor");
        if(typeof token == "string" && typeof email == "string") {
            this._token = token;
            this._email = email;
        }
        else {
            this._token = "";
            this._email = "";
        }

    }

    //Setters and getters

    set token(value : string) {
        this._token = value;
    }

    set email(value : string) {
        this._email = value;
    }

    get token() {
        return this._token;
    }

    get email() {
        return this._email;
    }
    

    //===========================================================
    //Helper Functions

    logout = () => {
        this.token = "";
        this.email = "";
    }

    //===========================================================
    //Account requests

    login = (email : string, password : string) : Promise<any> => {
        return new Promise((resolve, reject) => {
            if(this.email.length > 0 || this.token.length > 0) {
                reject("Already logged in");
            }

            login(email, password)
                .then((data) => {

                    this.token = data.token;
                    this.email = data.email;

                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    register = (accountName : string, email : string, password : string, password2: string) : Promise<any> => {
        return new Promise((resolve, reject) => {
            if(this.email.length > 0 || this.token.length > 0) {
                reject("Already logged in");
            }

            register(accountName, email, password, password2)
                .then((data) => {

                    this.token = data.token;
                    this.email = data.email;

                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    verifyLink = (verificationCode : string) : Promise<any> => {
        return verifyLink(verificationCode);
    }

    submitPasswordReset = (email : string) : Promise<any> => {
        return submitPasswordReset(email);
    }

    changePassword = (identifier: string, password : string, password2 : string) : Promise<any> => {
        return changePassword(identifier, password, password2);
    }

    //============================================================
    //Workout requests

    getAccountInfo = () : Promise<any> => {
        return getAccountInfo(this.email , this.token);
    }

    createWorkout = (data: any) : Promise<any> => {
        return createWorkout(this.email, data, this.token);
    }

    updateWorkout = (workoutId : number, data : any) : Promise<any> => {
        return updateWorkout(this.email, workoutId, data, this.token);
    }

    deleteWorkout = (workoutId: number) : Promise<any> => {
        return deleteWorkout(this.email, workoutId, this.token);
    }

    excelDownload = () : Promise<any> => {
        return excelDownload(this.email, this.token);
    }
}
