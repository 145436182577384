import React, {useState, useContext} from 'react';

import "./styles/register.scss";

import TextField from "../components/TextField";
import Button from "../components/Button";

import { UserContext, UserContextInterface  } from "../providers/userprovider";

type LoginProps = {
  context: UserContextInterface
}

const Register = (props : LoginProps) => {
    const context : UserContextInterface = useContext(UserContext);

    const [accountName, setAccountName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("")
    const [password2, setPassword1] = useState<string>("")
    const [errorMessage, setErrorMessage] = useState<any>("")

    const createAccount = () => {

        if(accountName.length === 0)
          return setErrorMessage("Name is empty")
        
        if(email.length === 0) 
          return setErrorMessage("Email is empty")
        
        if(password.length === 0) 
          return setErrorMessage("Password is empty")

        if(password2.length === 0) 
          return setErrorMessage("Confirm password is empty")

        if(password !== password2) 
          return setErrorMessage("Passwords do not match")

        context.api.register(accountName, email, password, password2)
          .then((data) => {
            context.login(data.token, data.email);
          })
          .catch((error) => {
            return setErrorMessage(JSON.stringify(error));
          })
        
        
    };

    return( <div className="home-root">

    <header>
        <h2 onClick={()=>{
          window.location.href = "/"
        }}>Apollo</h2>
    </header>

    <div className="inputs">
      
      <label style={{width: "100%", textAlign: "left"}}>Account Name</label>
      <TextField
        placeholder=""
        width="100%"
        value={accountName}
        onChange={(event : any) => {setAccountName(event.target.value)}}
      >

      </TextField>

      <label style={{width: "100%", textAlign: "left"}}>Email</label>
      <TextField
        placeholder=""
        width="100%"
        value={email}
        onChange={(event : any) => {setEmail(event.target.value)}}
      >

      </TextField>

      <label style={{width: "100%", textAlign: "left"}}>Password</label>
      <TextField
        placeholder=""
        width="100%"
        type="password"
        value={password}
        onChange={(event : any) => {setPassword(event.target.value)}}
      >

      </TextField>

      <label style={{width: "100%", textAlign: "left"}}>Confirm Password</label>
      <TextField
        placeholder=""
        width="100%"
        type="password"
        value={password2}
        onChange={(event : any) => {setPassword1(event.target.value)}}
      >

      </TextField>
      
      <div
        className="login-container"
      >Already have an account? <a href="/login">Sign In</a></div>
    
      <div className="error-messages">
        {errorMessage}
      </div> 

      <div
        className="create-account-button-container"
      >
        <Button
          width="100%"
          color="white"
          style={{
            "color": "black",
            "margin": "auto"
          }}
          onClick={() => {createAccount()}}
        >Create Account</Button>
      </div>
    </div>
  </div>)
}

export default Register;